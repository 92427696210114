import React from 'react';
import youtube from '../assets/images/youtube.png';
import linkedin from '../assets/images/linkedin.png';
import instagram from '../assets/images/instagram.png';
import facebook from '../assets/images/facebook.png';
import appstore from '../assets/images/appstore.png';
import googleplay from '../assets/images/googleplay.png';

import logofooter from '../assets/images/logo-footer.png'


export default function Footer() {
  return (
    <footer className="page-footer">
      <div className="container">
      <div className="row">
        <div className="col-md-7 d-flex p-2">
          <a target="_blank" href="https://www.linkedin.com/company/elia-games/"><img className="img-fluid" src={linkedin} /></a>
          <a target="_blank" href="https://apps.apple.com/fr/developer/elia-games/id1499972851"><img className="img-fluid" src={appstore} /></a>
          <a target="_blank" href="https://play.google.com/store/apps/developer?id=ELIA+GAMES"><img className="img-fluid" src={googleplay} /></a>
        </div>
        <hr className="clearfix w-100 d-md-none pb-3"></hr>
        <div className="col-md-5 d-flex justify-content-end">
          <img className="img-fluid" src={logofooter} /> 
          <div className="d-inline-flex flex-column justify-content-end align-items-start">
            <p className='elia mb-0'>SAS ELIA</p> 
            <p className='mb-1'>243 rue de l’œillade • Saint Gély du Fesc • France</p>
            <p>SIRET 88134372700015</p>
          </div>
        </div>
      </div>
      </div>
    </footer>
  );
}
